import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"cookies"} />
		<Helmet>
			<title>
				Wavedropper LTD - Empowering Your Digital Presence with Precision
			</title>
			<meta name={"description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:title"} content={"Wavedropper LTD - Empowering Your Digital Presence with Precision"} />
			<meta property={"og:description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/anim-optimized.gif?v=2024-07-04T11:36:37.486Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.TopNavBar />
		<Section margin="100px 0 0 0" position="relative">
			<Override slot="SectionContent" />
			<Text font="--headline1">
				Cookies
			</Text>
			<Components.Cookies position="relative" />
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65e4858a867c86001f1cafaf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});